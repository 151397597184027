/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM401P
 * 화면설명 : 비대면동의 승인현황 - 고객검색
 * 작 성 일 : 2023.03.21
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container :show-title="true" title="고객검색" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area pt30 pb80 mb30">

        <!-- 조직관련 COMPONENT : 관리자 접근시 버튼 노출 -->
        <SearchCondOrgCM ref="refSearchOrg" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData401P" @selectResult="fn_SlectCnsltResult"/>

        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <span class="label-title mb10">고객명</span>
          <mo-text-field  class="form-input-name full" v-model="lv_CustNm" underline clearable placeholder="고객명을 입력해주세요" />
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <span class="label-title mb10">승인일</span>
          <div class="ns-date-picker multi">
            <mo-date-picker v-model="lv_AprvDtmFrom" :min-date="minAprvDtm" :max-date="maxAprvDtm"/><span>~</span>
            <mo-date-picker v-model="lv_AprvDtmTo" :max-date="maxAprvDtm"/>
          </div>
        </ur-box-container>
        <!-- 고객상태코드(회수:01/미회수:02/철회대상:03) -->
        <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
          <span class="label-title mb10">상태</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_CustStatCd" class="chip-type-wrap">
              <mo-segment-button value="0">전체</mo-segment-button>
              <mo-segment-button value="02">미회수</mo-segment-button>
              <mo-segment-button value="01">회수완료</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClearBtn">초기화</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SearchBtn">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import moment from 'moment'
  
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: "MSPCM401P",
  screenId: "MSPCM401P",
  // 컴포넌트 선언
  components: {
    SearchCondOrgCM
  },
  props: {
    pOrgData: { // 조직정보
      type: Object,
      default: {}
    }
  },
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data () {
    return {
      minAprvDtm: '',
      maxAprvDtm: '',
      today: '',

      lv_SelectOrg: {},
      pOrgData401P: {},
      lv_CustNm: '',
      lv_AprvDtmFrom: '',
      lv_AprvDtmTo: '',
      
      lv_CustStatCd: '0' // 고객상태코드(전체:0/회수:01/미회수:02)
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM401P ■■■■■■■')

    this.today = moment(new Date()).format('YYYY-MM-DD') // 오늘 날짜
    let minAprvDtm = ''

    minAprvDtm = this.$cmUtil.dateCal(this.today, 'year', -1) // 최소 1년

    // 운영계 이관 (첫 서비스 제공 화면이라, 오픈하는 Month가 최소값)
    if (this.today < '2024-06-30') {
      minAprvDtm = '20220601' // 2023.06.01 기준 1년전
    } 

    this.minAprvDtm = this.$bizUtil.dateDashFormat(minAprvDtm)
    this.maxAprvDtm = this.today
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM400M')

    let aprvDtmTo = this.today // 오늘 날짜
    let aprvDtmFrom = this.$cmUtil.dateCal(aprvDtmTo, 'day', -30) // 30일 전 (기획자와 협의후, 결정사항)
    aprvDtmFrom = this.$bizUtil.dateDashFormat(aprvDtmFrom)

    // 조건 조회 정보 세팅
    if (!_.isEmpty(this.pOrgData)) {
      this.pOrgData401P = this.pOrgData.orgData // 조회 조직
      this.lv_CustNm = this.pOrgData.custNm // 조회 고객명

      aprvDtmFrom = this.$bizUtil.dateDashFormat(this.pOrgData.aprvDtmFrom) // 조회 승인일
      aprvDtmTo = this.$bizUtil.dateDashFormat(this.pOrgData.aprvDtmTo) // 조회 승인일

      this.lv_CustStatCd = this.pOrgData.custStatCd // 조회 고객상태코드
    }

    this.lv_AprvDtmFrom = aprvDtmFrom // 승인일 From
    this.lv_AprvDtmTo = aprvDtmTo // 승인일 To
    // console.log(this.lv_AprvDtmFrom +'~'+ this.lv_AprvDtmTo)
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init() {
      this.lv_SelectOrg = {}
      this.lv_CustNm = ''
      
      let aprvDtmFrom = this.$cmUtil.dateCal(this.today, 'month', -1)

      this.lv_AprvDtmFrom = this.$bizUtil.dateDashFormat(aprvDtmFrom)
      this.lv_AprvDtmTo = this.$bizUtil.dateDashFormat(this.today)

      this.lv_CustStatCd = '0'
    },
    /*********************************************************
     * @function fn_ClearBtn
     * @notice   모든 조건 초기화
    *********************************************************/
    fn_ClearBtn () {
      // 조직 컴포넌트 초기화
      this.$refs.refSearchOrg.fn_ClearOrg()

      this.fn_Init()
    },
    /*********************************************************
     * @function fn_SearchBtn
     * @notice   조회 버튼 클릭 이벤트
    *********************************************************/
    fn_SearchBtn () {
      if (!this.fn_SuitaAprvDtm()) return

      let rtnObj = {
        orgData: this.lv_SelectOrg,
        custNm: this.lv_CustNm,
        aprvDtmFrom: this.lv_AprvDtmFrom ? this.lv_AprvDtmFrom.split('-').join('') : '',
        aprvDtmTo: this.lv_AprvDtmTo ? this.lv_AprvDtmTo.split('-').join('') : '',
        custStatCd: this.lv_CustStatCd
      }

      this.$emit('onPopupConfirm', rtnObj)
    },
    /*********************************************************
     * @function fn_SuitaAprvDtm
     * @notice   승인일 조회 조건 적합 체크
    *********************************************************/
    fn_SuitaAprvDtm () {
      let rtnValu = false

      if (new Date(this.lv_AprvDtmFrom) < new Date(moment(this.lv_AprvDtmTo).add(-1, 'months'))) {
        let toastMsg = '검색일자의 범위는 1개월 이내만 가능합니다.'
        this.$addSnackbar(toastMsg)
      } else {
        rtnValu = true
      }

      return rtnValu
    },
    /******************************************************************************
     * @function fn_SlectCnsltResult
     * @notice   조직 변경시, 전역변수에 셋팅
    ******************************************************************************/
    fn_SlectCnsltResult (pData) {
      this.lv_SelectOrg = pData
    }
  }
};
</script>
