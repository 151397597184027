/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM400M
 * 화면설명 : 지류동의서 승인현황
 * 작 성 일 : 2023.03.21
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-page-container class="msp" title="지류동의서 승인현황" :show-title="true" type="subpage"  action-type="search" :topButton="true" :topButtonBottom="topButtonBottom"
                    @action-search-click="fn_OpenMSPCM401P()" @on-header-left-click="fn_HistoryBack">
     <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
        <!-- 상단문구 -->
        <div class="text-gray-box bgcolor-2 bd-radius-0 pt20">
          <ul class="terms-list-area fs14rem">
            <li class="crTy-bk7">승인일로부터 5일 내 원본회수&대조&지점장 서명하여 기존 가이드에 따라 원본 보관바랍니다.</li>
          </ul>
        </div>
        <!-- 상단문구 //-->
        <!-- 검색결과 카테고리 출력 -->
        <ur-box-container v-if="isSrchLabel" alignV="start" direction="column" class="search-result-box bd-T-Ty1 bd-b-Ty1"> 
          <strong class="tit">검색결과</strong>
          <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
            <mo-tab-label idx="1" @click="fn_OpenMSPCM401P">
              <span class="txt">{{ lv_OrgData.hofOrg.label }}</span> 
            </mo-tab-label>
            <mo-tab-label idx="2" @click="fn_OpenMSPCM401P">
              <span class="txt">{{ lv_OrgData.dofOrg.label }}</span> 
            </mo-tab-label>
            <mo-tab-label idx="3" @click="fn_OpenMSPCM401P">
              <span class="txt">{{ lv_OrgData.fofOrg.label }}</span> 
            </mo-tab-label>
            <mo-tab-label idx="4" @click="fn_OpenMSPCM401P">
              <span class="txt">컨설턴트 {{ lv_OrgData.cnslt.label}}</span> 
            </mo-tab-label>
            <mo-tab-label idx="5" @click="fn_OpenMSPCM401P" v-if="!$bizUtil.isEmpty(lv_CustNm)">
              <span class="txt">고객 {{ lv_CustNm }}</span> 
            </mo-tab-label>
            <mo-tab-label idx="6" @click="fn_OpenMSPCM401P">
              <span class="txt">승인일 {{ $bizUtil.dateDashFormat(lv_AprvDtmFrom) }}~{{ $bizUtil.dateDashFormat(lv_AprvDtmTo) }}</span> 
            </mo-tab-label>
          </mo-tab-box>
        </ur-box-container>
        <!-- 검색결과 카테고리 출력 //-->

        <!-- 총명수  -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
          <mo-list-item>
            <mo-checkbox v-model="isAllCheck" @input="fn_AllCheckHandler" size="small" :disabled="fn_CmptdAprvCustTot < 1"/>
            <div v-if="checkCount < 1" class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총 <strong class="crTy-blue3">{{ fn_CmptdAprvCustTot }}</strong> 명</span>
              </div>
            </div>
            <div v-else class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum"><strong class="crTy-blue3">{{ checkCount }}</strong> 명 선택</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- 총명수  //-->

      </ur-box-container>
    </template>

    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 

      <ur-box-container v-if="fn_CmptdAprvCustTot > 0" direction="column" alignV="start" class="ns-list-area msp-list-area"> 
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list type-2 mb50" :class="{'mb150' : checkCount > 0}">
          <mo-list :list-data="lv_AprvCustList">
            <template #list-item="{item}">
              <mo-list-item class="type-itm-2" :class="{'on' : item.checked === true}">
                <mo-checkbox v-model="item.checked" :disabled="item.custStatCd === '01'"/>
                <div class="list-item__contents" @click="item.checked = !item.checked">
                  <div class="list-item__contents__title mb10 fexTy3">
                    <span class="name txtSkip">{{ item.cnsltNm }}({{ item.cnsltEno }})</span>
                    <!-- 회수/미회수 뱃지 2023.04.03 철회대상(03) 고객도 미회수로 노출 -->
                    <mo-badge class="badge-sample-badge sm" :class="{'lightred' : item.custStatCd !== '01', 'lightblue' : item.custStatCd === '01'}" text="" shape="status">
                      {{ item.custStatCd === '01' ? '회수완료' : '미회수' }}
                    </mo-badge>
                  </div>
                  <div class="list-item__contents__title">
                    <span class="tit fs16rem fwn txtSkip">{{ item.custNm }}</span>
                  </div>
                  <!-- 주고객 관계 (본인일 경우 미노출) -->
                  <div class="list-item__contents__title" v-if="item.mnCustRltnCd !== '01'">
                    <span class="tit fs16rem fwn txtSkip">{{ item.mnCustNm }}의 {{ fn_CmptdCustRlntNm(item.mnCustRltnCd) }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="min50 fs14rem crTy-bk7 mr10">승인일시</span>
                    <span class="crTy-bk1">{{ fn_CmptdAprvDtm(item.aprvDtm) }}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>
      </ur-box-container>
      
      <!-- NoData 영역 ::START  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역 ::END  -->
      
    </ur-box-container>
    <!-- 컨텐츠영역  //-->

    <!-- 바텀시트 영역 ::START -->
    <div class="ns-bottom-sheet">
      <!-- 하단 버튼(전화/문자/고객카드/더보기) -->
      <mo-bottom-sheet ref="bottomNaviMenu" :close-btn="false" :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs pal2430 noshadow">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="medium" class="ns-btn-round blue" @click="fn_ShowWdrwCnfm">회수확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 회수 처리 컨펌 -->
      <mo-bottom-sheet ref="bottomWdrwCnfm" class="ns-bottom-sheet ns-style3">
        <div>
          <p class="cancel-ment">
            고객동의는 개인정보보호법에 의거하여 고객의 의사를 확인하여 받아야합니다.<br>
            <br>
            미자필 또는 원본 분실시 개인정보보호법<br>
            위반 및 voc의 원인이 될수있으므로 원본 확인 부탁드립니다.<br>
            <br>
            회수 처리하시겠습니까?
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CancelWdrw">취소</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CnfmWdrw">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 회수 처리 컨펌 -->
    </div>
    <!-- 바텀시트 영역 ::END -->

  </ur-page-container>
</template>
<script>
/***********************************************************************************
* INCLUDE 영역                                                                    *
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import cmConstants from '~/src/config/constants/cmConstants';

import MSPCM401P from '@/ui/cm/MSPCM401P'
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 화면명
  name: 'MSPCM400M', 
  // 화면ID
  screenId: 'MSPCM400M',
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data () {
    return {
      isAllCheck: false, // 전체 선택 여부
      
      isSrchLabel: false, // 라벨 노출 여부
      lv_UserInfo: {}, // 사용자 정보

      lv_SearchData: {}, // 조회 조건 Object
      lv_OrgData: {},    // 조직 정보 Object

      lv_CustNm: '', // 조회 고객명
      lv_AprvDtmFrom: '', // 조회 승인일자 시작일
      lv_AprvDtmTo: '',   // 조회 승인일자 마지막일

      lv_AprvCustList: [], // 승인고객 현황 리스트
      lv_AprvOrgCustList: [],
      alertProps: {}, // 알럿 Props

      positionUp: false,  // 하단버튼 바텀시트 오픈여부
    };
  },
  /***********************************************************************************
   * watch 정의 영역
  ***********************************************************************************/
  watch: {
    lv_AprvOrgCustList() {
      let rtnList = []
      let custList = this.lv_AprvOrgCustList

      let unAprvList = custList.filter(item => {
          return item.custStatCd === '02' || item.custStatCd === '03'
        });
      let aprvList = custList.filter(item => {
          return item.custStatCd === '01'
        });

      // 오래된 순으로 정렬(aprvEndYmd: 유효종료일)
      unAprvList = unAprvList.sort((a,b) => a.aprvDtm - b.aprvDtm)

      rtnList = [...unAprvList, ...aprvList]
      this.lv_AprvCustList = rtnList
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
    // 전체 고객 수
    fn_CmptdAprvCustTot() {
      let aprvCustList = this.lv_AprvCustList
      return aprvCustList ? aprvCustList.length : 0
    },
    // 체크된 고객 수
    checkCount() {
      if (this.fn_CmptdAprvCustTot < 1) return 0;

      let chkList = this.lv_AprvCustList.filter(item => {
          return item.checked === true
        });

      let wdrwList = this.lv_AprvCustList.filter(item => {
          return item.custStatCd !== '01' // 회수완료건 제외
        });

	    // 체크 된 고객 존재시 GNB 노출
      if (chkList.length > 0) {
        // this.positionUp = true
        this.$refs.bottomNaviMenu.open()
      } else {
        // this.positionUp = false
        this.$refs.bottomNaviMenu.close()
      }

      // this.$cmUtil.setSelectedCustList(this, chkList)

      // 전체선택 체크박스값 변경
      if (wdrwList.length > 0 && chkList.length === wdrwList.length) {
        this.isAllCheck = true
      } else {
        this.isAllCheck = false
      }

      return chkList ? chkList.length : 0
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM400M ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
    
    this.fn_Init()

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM400M')
  },
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {

      // FC채널 지점장만 메뉴 사용가능
      if (!['FC', 'TC'].includes(this.lv_UserInfo.chnlScCd)) {
        let alertMsg = '권한이 없습니다.'
        this.alertProps = {single: true, content: alertMsg}
        this.fn_ShowBottomAlert()
        return
      }

      // 초기 조회 파라미터 셋팅
      this.fn_SetParams()

      // 승인현황 고객 조회
      this.fn_SelAprvCustList()
    },
    /*********************************************************
     * @function fn_CmptdAprvDtm
     * @notice   승인 일시 날짜 포맷(YYYY-MM-DD HH:mm)
    *********************************************************/
    fn_CmptdAprvDtm(aprvDtm) {
      let rtnValu = ''

      if (!this.$bizUtil.isEmpty(aprvDtm)) {
        rtnValu = moment(aprvDtm.substr(0,8)).format('YYYY-MM-DD') + " " + aprvDtm.substr(8,2) + ":" + aprvDtm.substr(10,2)
      }

      return rtnValu
    },
    /*********************************************************
     * @function fn_CmptdCustRlntNm
     * @notice   주고객 관계 코드명 Getting
    *********************************************************/
    fn_CmptdCustRlntNm(RlntCd) {
      let rlntObj = cmConstants.CUST_RLTN_CD.filter(item => {
        return item.key === RlntCd
      })[0]

      return rlntObj ? rlntObj.label : ''
    },
    /*********************************************************
     * @function fn_OpenMSPCM401P
     * @name     팝업호출
     * @notice   고객검색 팝업 호출
    *********************************************************/
    fn_OpenMSPCM401P () {
      const lv_Vm = this

      // FC채널 지점장만 메뉴 사용가능
      if (!['FC', 'TC'].includes(this.lv_UserInfo.chnlScCd)) {
        let alertMsg = '권한이 없습니다.'
        this.alertProps = {single: true, content: alertMsg}
        this.fn_ShowBottomAlert()
        return
      }

      // console.log('lv_SearchData', this.lv_SearchData)
      let popObj = this.$bottomModal.open(MSPCM401P, {
        properties: {
          pOrgData: this.lv_SearchData // (최근 조회 조건)
        },
        listeners: {
          onPopupConfirm: (pData) => {
            if (!_.isEmpty(pData)) {
              lv_Vm.fn_PopupCallback(pData) // 조회 조건 정제
              lv_Vm.fn_SelAprvCustList()    // 조회 조건으로 승인고객 조회
            }
            this.$bottomModal.close(popObj)
          }
        }
      });

    },
    /*********************************************************
     * @function fn_PopupCallback
     * @name     팝업콜백함수
     * @notice   고객검색 팝업 콜백 함수
     *           조건 조회로 파라미터 Setting
     *           - 승인 고객 상태 코드 (회수:01/미회수:02/철회대상:03)
    *********************************************************/
    fn_PopupCallback (pData) {
      // 조회 조건 Setting
      this.lv_SearchData = pData ? pData : {}
      this.lv_OrgData = pData.orgData ? pData.orgData : {}
      this.isSrchLabel = true

      this.lv_AprvDtmFrom = pData.aprvDtmFrom // 조회조건 승인일 시작일자
      this.lv_AprvDtmTo = pData.aprvDtmTo     // 조회조건 승인일 마지막일자
      this.lv_CustNm = pData.custNm           // 고객명
      this.lv_CustStatCd = pData.custStatCd   // 승인 고객 상태 코드

      this.fn_SetParams('search') // 조회 조건 파라미터 정제
    },
    /*********************************************************
     * @function fn_SelAprvCustList
     * @name     승인고객현황조회
     * @notice   승인 고객 현황 조회
     * @param {Object} { custNm cnsltNo aprvDtmFrom aprvDtmTo custStatCd }
    *********************************************************/
    fn_SelAprvCustList () {
      const lv_Vm = this

      let pParam = this.lv_pParams

      const trnstId = 'txTSSCM34S3'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          console.log(res.body)

          if (!_.isEmpty(res.body) && !_.isEmpty(res.body.aprvCustList)) {
            let custList = res.body.aprvCustList
            
            // 체크박스
            custList.forEach(item => {
              item.checked = false
            });

            // 승인 고객 리스트 셋팅
            this.lv_AprvOrgCustList = custList
          } else {
            // 결과값 없을경우, 빈값처리
            this.lv_AprvCustList = []
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_SortCustList
     * @name     조회리스트정렬
     * @notice   승인 고객 리스트 정렬
     *            - 정렬 기준
     *              1. 고객 상태코드 (미회수 고객 최상단)
     *              2. 오래된 순
    *********************************************************/
    fn_SortCustList(custList) {
      let rtnList = []

      let unAprvList = custList.filter(item => {
          return item.mnCustRltnCd === '02' || item.mnCustRltnCd === '03'
        });
      let aprvList =custList.filter(item => {
          return item.mnCustRltnCd === '01'
        });

      // 오래된 순으로 정렬(aprvEndYmd: 유효종료일)
      unAprvList = unAprvList.sort((a,b) => a.aprvDtm - b.aprvDtm)

      rtnList = [...unAprvList, ...aprvList]
      this.lv_AprvCustList = rtnList
    },
    /*********************************************************
     * @function fn_SetParams
     * @name     파라미터정제
     * @notice   승인고객 현황 조회 파라미터 Setting
    *********************************************************/
    fn_SetParams (flag) {
      if (flag === 'search') { // 조건 조회
        this.lv_pParams = {
          custNm: this.lv_CustNm, // 고객명
          cnsltNo: this.lv_OrgData.cnslt.key, // 컨설턴트 사번
          fofOrgNo: this.lv_OrgData.fofOrg.key, // 조회 지점번호
          aprvDtmFrom: this.lv_AprvDtmFrom, // 조회조건 승인일 시작일
          aprvDtmTo: this.lv_AprvDtmTo, // 조회조건 승인일 마지막일
          custStatCd: this.lv_CustStatCd  // 고객상태코드
        }
      } else { // 초기 진입 조회 (사용자 정보 셋팅)
        let today = moment(this.$cmUtil.fn_CurrentDate()).format('YYYY-MM-DD')

        this.lv_pParams = {
          custNm: '',
          cnsltNo: '0', // 본인 지점(전체)
          fofOrgNo: this.lv_UserInfo.onpstFofOrgNo, // 조회 지점번호
          aprvDtmFrom: this.$cmUtil.dateCal(today, 'day', -30), // default: 한달 전
          aprvDtmTo: this.$cmUtil.fn_CurrentDate(),  // 오늘 날
          custStatCd: '0'  // 고객상태코드(전체)
        }
      }
    },
    /*********************************************************
     * @function fn_UpdWdrwTrt
     * @name     승인고객_지류회수완료_처리
     * @notice   선택한 고객 회수 완료 처리
     * @param {List} 필수값: [{ chnlCtgrId chnlCustId cnsltNm unAprvYn }]
    *********************************************************/
    fn_UpdWdrwTrt () {
      const lv_Vm = this

      let chkList = lv_Vm.lv_AprvCustList.filter(item => {
          return item.checked
        });

      let pParam = {
        aprvCustList: [...chkList] // 선택한 고객리스트
      }

      const trnstId = 'txTSSCM34I1'
      const auth = 'U'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && res.body.trnsRsltCd === 'S') {
            lv_Vm.isAllCheck = false

            if (!_.isEmpty(res.body.aprvCustList)) {
              let custList = res.body.aprvCustList

              // 체크박스 모두 해제, 변경된 상태코드로 업데이트
              for (let i=0; i<custList.length; i++) {
                lv_Vm.lv_AprvCustList.forEach(item => {
                  item.checked = false
                  if (item.chnlCustId === custList[i].chnlCustId) {
                    item.custStatCd = custList[i].custStatCd
                  }
                });

              }
              lv_Vm.lv_AprvOrgCustList = lv_Vm.lv_AprvCustList
            }
            this.$addSnackbar("회수가 완료되었습니다.");
          } else {
            this.$addSnackbar("회수 처리 실패하였습니다.");
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_ShowWdrwCnfm/fn_CancelWdrw
     * @name     회수처리_컨펌_시트
     * @notice   지류 회수 완료 호출 전 확인 바텀시트
     *           바텀시트 open/close
    *********************************************************/
    fn_ShowWdrwCnfm () { this.$refs.bottomWdrwCnfm.open() },
    fn_CancelWdrw () { this.$refs.bottomWdrwCnfm.close() },
    /*********************************************************
     * @function fn_CnfmWdrw
     * @notice   회수처리 확인 버튼 클릭시, 호출
     *            - 바텀시트 닫으면서, 회수처리 함수 호출한다.
    *********************************************************/
    fn_CnfmWdrw () {
      this.fn_CancelWdrw()
      this.fn_UpdWdrwTrt()
    },
    /*********************************************************
     * @function fn_HistoryBack
     * @notice   뒤로가기버튼 클릭시 호출
     *            - 조건 조회 내역이 존재시, fn_Init 호출로 초기화
     *            - 없을시, HisotyBack
    *********************************************************/
    fn_HistoryBack () {
      if (!_.isEmpty(this.lv_SearchData)) { // 최근 조회
        this.lv_SearchData = {}
        this.lv_OrgData = {}
        this.isSrchLabel = false
        this.lv_AprvCustList = []

        this.fn_Init()
      } else {
        this.$router.go(-1)
      }
    },
    /*********************************************************
     * @function fn_AllCheckHandler
     * @notice   전체 선택 체크박스
     *            조회된 고객 전체선택 on/off
    *********************************************************/
    fn_AllCheckHandler () {
      const lv_Vm = this

      lv_Vm.lv_AprvCustList.forEach(item => {
          if (item.custStatCd !== '01') {
            item.checked = lv_Vm.isAllCheck
            // this.$set(item, 'checked', lv_Vm.isAllCheck)
          }
        });

      let chkList = this.lv_AprvCustList.filter(item => {
          return item.checked === true
        });
      let wdrwList = this.lv_AprvCustList.filter(item => {
          return item.custStatCd !== '01' // 회수완료건 제외
        });

      // 전체선택 체크박스값 변경
      if (wdrwList.length > 0 && chkList.length === wdrwList.length) {
        this.isAllCheck = true
      } else {
        this.isAllCheck = false
      }

    },
    /**************************************************************************************
     * @function fn_ShowBottomAlert
     * @name     BottomAlert_Component_호출_함수
     * @param    fn_CallBack(type: function)
     * @notice   Alert Property는 해당 함수 호출전에 셋팅한다.
     *           Alert Confirm Event는 Function을 파라미터로 받는다.
     *                 Confirm/Close: 전역변수로 셋팅된 Property 초기화
    **************************************************************************************/
    fn_ShowBottomAlert (fn_CallBack) {
      const lv_Vm = this

      let lv_Alert = this.$bottomModal.open(MSPBottomAlert, {
        properties: lv_Vm.alertProps,
        listeners: {
          onPopupConfirm: () => {
            if (typeof(fn_CallBack) === 'function') {
              fn_CallBack()
            }
            // 알럿 props 초기화
            lv_Vm.alertProps = {}
            this.$bottomModal.close(lv_Alert)
          },
          onPopupClose: () => {
            // 알럿 props 초기화
            lv_Vm.alertProps = {}
            this.$bottomModal.close(lv_Alert)
          },
        }
      });

    },
  } // ::Methods End
};
</script>
